<template>
  <div class="app-main">
    <Navbar v-if="activePage!=='/index'"></Navbar>
    <router-view></router-view>
  </div>
</template>

<script>
import Navbar from "@/views/layout/components/Navbar";
import store from "@/store";

export default {
  name: "AppMain",
  components: {Navbar},
  watch: {
    $route(to) {
      this.activePage = to.path
    }
  },
  data() {
    return {
      activePage: '',
    }
  },
  mounted() {
    this.activePage = this.$route.path;
  }
}
</script>

<style scoped>

</style>
