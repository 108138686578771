<template>
  <div class="Navbar">
    <div class="navbar_bg">
      <img v-if="navbar.img==='navbar_bg_hb'" src="~@/assets/images/layout/lsqs.webp" width="100%"/>
      <img v-if="navbar.img==='navbar_bg_technology'" src="~@/assets/images/layout/lsqs.webp" width="100%"/>
      <img v-if="navbar.img==='navbar_bg_achtrans'" src="~@/assets/images/layout/lsqs.webp" width="100%"/>
      <img v-if="navbar.img==='navbar_bg_about_us'" src="~@/assets/images/layout/lsqs.webp" width="100%"/>
      <img v-if="navbar.img==='navbar_bg_demand'" src="~@/assets/images/layout/lsqs.webp" width="100%"/>
      <img v-if="navbar.img==='navbar_bg_information'" src="~@/assets/images/layout/navbar_bg_information.webp" width="100%"/>
    </div>
    <div class="breadcrumb app_common_lb">
      <img src="~@/assets/images/layout/main.png" height="15px" width="15px"/>&nbsp;
      <span class="dq_span">当前位置：</span>
      <el-breadcrumb separator=">">
        <template v-for="(item,index) in breadCrumb">
          <el-breadcrumb-item v-if="item.meta&&index!==(breadCrumb.length-1)" :to="{ path: item.path }">{{ item.name }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else>{{ item.name }}</el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  name: "Navbar",
  computed: {
    navbar() {
      return store.getters.navbar;
    },
    breadCrumb() {
      return store.getters.breadCrumb;
    }
  }
}
</script>

<style scoped lang="scss">
.Navbar {
  .dq_span {
    font-size: 14px;
    color: #303133;
    position: relative;
    top: -2px;
  }

  .breadcrumb {
    padding-top: 21px;
    height: 60px;

    .el-breadcrumb {
      width: 80%;
      display: inline-block;
    }
  }
}
</style>

<!--<style lang="scss">
.el-breadcrumb .el-breadcrumb__item .is-link, .el-breadcrumb {
  font-size: 18px;
  font-weight: 700 !important;
}
</style>-->
