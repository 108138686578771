const homePage = {
    state: {
        navbar: JSON.parse(sessionStorage.getItem("page_navbar")) || {
            firstName: '首页',
            secondName: '首页',
            enName: 'index'
        },
        activePage: sessionStorage.getItem("active_page") || 'index',
        activePageParent: sessionStorage.getItem("active_page_parent") || 'index',
        breadCrumb: JSON.parse(sessionStorage.getItem("bread_crumb")) || [],
    },
    mutations: {
        SET_NAVBAR: (state, navbar) => {
            state.navbar = navbar
            sessionStorage.setItem("page_navbar", JSON.stringify(navbar))
        },
        SET_ACTIVE_PAGE: (state, activePage) => {
            state.activePage = activePage
            sessionStorage.setItem("active_page", activePage)
        },
        SET_ACTIVE_PAGE_PARENT: (state, activePageParent) => {
            state.activePageParent = activePageParent
            sessionStorage.setItem("active_page_parent", activePageParent)
        },
        SET_BREAD_CRUMB: (state, breadCrumb) => {
            state.breadCrumb = breadCrumb
            sessionStorage.setItem("bread_crumb", JSON.stringify(breadCrumb))
        },
    },
    actions: {
        setNavbar({commit}, navbar) {
            commit('SET_NAVBAR', navbar)
        },
        setActivePage({commit}, activePage) {
            commit('SET_ACTIVE_PAGE', activePage)
        },
        setActivePageParent({commit}, activePageParent) {
            commit('SET_ACTIVE_PAGE_PARENT', activePageParent)
        },
        setBreadCrumb({commit}, breadCrumb) {
            commit('SET_BREAD_CRUMB', breadCrumb)
        },
    }
}

export default homePage
