import '@/assets/styles/index.scss' // global css
import 'swiper/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css';
import {parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree} from "@/utils/ruoyi";
// 字典数据组件
import DictData from '@/components/DictData'
// 分页组件
import Pagination from "@/components/Pagination";
// 图片上传组件
import plugins from './plugins' // plugins
import Vue from 'vue'
import App from './App.vue'
import router from './router/index';
import ElementUI from 'element-ui';
import store from './store';
import ImageUpload from "@/components/ImageUpload/index.vue";
import FileUpload from "@/components/FileUpload/index.vue";
// 字典标签组件
import DictTag from '@/components/DictTag'
import './permission' // permission control
import WangEditor from "@/components/WangEditor/index.vue";
// 全局组件挂载
Vue.component('DictTag', DictTag)

Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.handleTree = handleTree

DictData.install()



Vue.use(ElementUI);
Vue.use(plugins)
Vue.component('Pagination', Pagination)
Vue.component('ImageUpload', ImageUpload)
Vue.component('WEditor', WangEditor)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
