import Vue from 'vue'
import Vuex from 'vuex'
import homePage from "./modules/homePage";
import getters from './getters'
import user from "@/store/modules/user";

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    homePage,
    user
  },
  getters
})

export default store
