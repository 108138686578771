import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Layout from '@/views/layout/Layout';
import assistance from "@/views/assistance/index.vue";

export const routerMap = [
    {path: '/404', component: () => import('@/views/errorPage/404'), hidden: true},
    {path: '/login', component: () => import('@/views/login'), hidden: true},
    {
        path: '',
        redirect: 'index',
        hidden: true
    },
    {
        path: '/indexNew',
        component: () => import('@/views/index/indexNew'),
        meta: {key: 'indexNew', scroll: '0'},
        listFlag: false, //是否是下拉
        hidden: true,
    },
    {
        path: '',
        component: Layout,
        meta: {key: 'index', scroll: '0'},
        listFlag: false, //是否是下拉
        hidden: false,
        children: [
            {
                path: '/index',
                meta: {img: 'navbar_bg_hb', key: 'index', keyParent: 'index'},
                component: () => import('@/views/index/index'),
                name: '首页',
                hidden: false
            },
        ]
    },
    {
        path: '',
        component: Layout,
        name: '最新资讯',
        meta: {key: 'information', scroll: '400'},
        listFlag: true, //是否是下拉
        hidden: false,
        children: [
            {
                path: '/environmentalInformation',
                meta: {img: 'navbar_bg_information', key: 'environmentalInformation', keyParent: 'information', tabId: '1'},
                component: () => import('@/views/information/environmentalInformation'),
                name: '环境资讯',
                hidden: false,
            },
            {
                path: '/industryInformation',
                meta: {img: 'navbar_bg_information', key: 'industryInformation', keyParent: 'information', tabId: '2'},
                component: () => import('@/views/information/industryInformation'),
                name: '行业资讯',
                hidden: false,
            },
            {
                path: '/informationDetail/:id(\\d+)',
                meta: {img: 'navbar_bg_information', key: 'informationDetail', keyParent: 'information'},
                component: () => import('@/views/information/informationDetail'),
                name: '详情信息',
                hidden: true
            }
        ]
    },
    {
        path: '',
        component: Layout,
        name: '政策文件',
        meta: {key: 'policyFile', scroll: '727'},
        listFlag: true, //是否是下拉
        hidden: false,
        children: [
            /* {
                 path: '/industryList',
                 meta: {img: 'navbar_bg_information', key: 'industryList', keyParent: 'policyFile',tabId:'1'},
                 component: () => import('@/views/information/industryList'),
                 name: '政策解读',
                 hidden: false,
             },*/
            {
                path: '/industryPolicy',
                meta: {img: 'navbar_bg_information', key: 'industryPolicy', keyParent: 'policyFile', tabId: '5'},
                component: () => import('@/views/technology/national'),
                name: '产业政策',
                hidden: false,
            },
            /*{
                path: '/finance',
                meta: {img: 'navbar_bg_information', key: 'finance', keyParent: 'policyFile',tabId:'2'},
                component: () => import('@/views/environmental/finance'),
                name: '金融项目库',
                hidden: false
            },*/
            {
                path: '/national',
                meta: {img: 'navbar_bg_information', key: 'national', keyParent: 'policyFile', tabId: '3'},
                component: () => import('@/views/technology/national'),
                name: '国家目录',
                hidden: false,
            },
            {
                path: '/lowCarbon',
                meta: {img: 'navbar_bg_information', key: 'lowCarbon', keyParent: 'policyFile', tabId: '4'},
                component: () => import('@/views/technology/lowCarbon'),
                name: '省级目录',
                hidden: false,
            },
            {
                path: '/policyDetail/:id(\\d+)',
                meta: {img: 'navbar_bg_information', key: 'policyDetail', keyParent: 'policyFile'},
                component: () => import('@/views/information/informationDetail'),
                name: '详情信息',
                hidden: true,
            },
            {
                path: '/financeDetail/:id(\\d+)',
                meta: {img: 'navbar_bg_information', key: 'financeDetail', keyParent: 'policyFile'},
                component: () => import('@/views/environmental/financeDetail'),
                name: '详情信息',
                hidden: true
            }
        ]
    },
    {
        path: '',
        component: Layout,
        name: '技术成果',
        meta: {key: 'technology', scroll: '1535'},
        listFlag: true, //是否是下拉
        hidden: false,
        children: [
            {
                path: '/technologyCollection',
                meta: {img: 'navbar_bg_technology', key: 'technologyCollection', keyParent: 'technology'},
                component: () => import('@/views/technology/technologyList'),
                name: '技术列表',
                hidden: false,
            },
            {
                path: '/technologyUploadNew',
                meta: {img: 'navbar_bg_technology', key: 'technologyUploadNew', keyParent: 'technology'},
                component: () => import('@/views/technology/technologyUploadIndex'),
                name: '技术上传',
                hidden: false,
            },
            {
                path: '/technology/:id(\\d+)',
                meta: {img: 'navbar_bg_technology', key: 'technologyDetail', keyParent: 'technology'},
                component: () => import('@/views/technology/technologyDetail'),
                name: '技术成果详情',
                hidden: true
            },
            {
                path: '/technologyUpload/person/:id(\\d+)',
                meta: {img: 'navbar_bg_technology', key: 'technologyUpload/person', keyParent: 'technology'},
                component: () => import('@/views/technology/technologicalManage/technologyAdd'),
                name: '技术上传-个人页跳转',
                hidden: true,
            },
            {
                path: '/technologyUploadNew/technologyAdd/:id(\\d+)',
                meta: {img: 'navbar_bg_technology', key: 'technologyAdd', keyParent: 'technology'},
                component: () => import('@/views/technology/technologicalManage/technologyAdd'),
                name: '添加技术成果',
                hidden: true,
            },
            {
                path: '/technologyUploadNew/companyAdd/:id(\\d+)',
                meta: {img: 'navbar_bg_technology', key: 'companyAdd', keyParent: 'technology'},
                component: () => import('@/views/technology/companyManage/companyAdd'),
                name: '添加企业',
                hidden: true,
            },
            {
                path: '/technologyUploadNew/caseAdd/:id(\\d+)',
                meta: {img: 'navbar_bg_technology', key: 'caseAdd', keyParent: 'technology'},
                component: () => import('@/views/technology/caseManage/caseAdd'),
                name: '添加典型案例',
                hidden: true,
            },
            {
                path: '/lowCarbonDetail/:id(\\d+)',
                meta: {img: 'navbar_bg_information', key: 'lowCarbonDetail', keyParent: 'technology'},
                component: () => import('@/views/information/informationDetail'),
                name: '详情信息',
                hidden: true,
            }
        ]
    },
    {
        path: '',
        component: Layout,
        name: '需求对接',
        meta: {key: 'demand', scroll: '2288'},
        listFlag: true, //是否是下拉
        hidden: false,
        children: [
            {
                path: '/demandInfo',
                meta: {img: 'navbar_bg_demand', key: 'demandInfo', keyParent: 'demand'},
                component: () => import('@/views/demand/demandInfo'),
                name: '需求列表',
                hidden: false,
            },
            {
                path: '/demandUpload',
                meta: {img: 'navbar_bg_demand', key: 'demandUpload', keyParent: 'demand'},
                component: () => import('@/views/demand/demandUpload'),
                name: '需求上传',
                hidden: false,
            },
            {
                path: '/demandUpload/person/:id(\\d+)',
                meta: {img: 'navbar_bg_demand', key: 'demandUpload/person', keyParent: 'demand'},
                component: () => import('@/views/demand/demandUploadPerson'),
                name: '需求上传',
                hidden: true,
            },
            {
                path: '/demandInfoDetail/:id(\\d+)',
                meta: {img: 'navbar_bg_demand', key: 'demandInfoDetail', keyParent: 'demand'},
                component: () => import('@/views/demand/demandInfoDetail'),
                name: '需求信息详情',
                hidden: true,
            }
        ]
    },
    {
        path: '',
        component: Layout,
        name: '成果转化',
        meta: {key: 'achtrans', scroll: '2794'},
        listFlag: true, //是否是下拉
        hidden: false,
        children: [
            /*{
                path: '/achtransFour',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransFour', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalIncubation/components/demonstrationCreation'),
                name: '转移转化基地',
                hidden: false,
            },
            {
                path: '/achtransone',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransone', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalAssessment/index'),
                name: '技术评估',
                hidden: false,
            },
            {
                path: '/achtranstwo',
                meta: {img: 'navbar_bg_achtrans', key: 'achtranstwo', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalSecondaryDevelopment/index'),
                name: '技术二次开发',
                hidden: false,
            },
            {
                path: '/achtransthree',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransthree', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalIncubation/index'),
                name: '技术交易与孵化',
                hidden: false,
            },
            {
                path: '/achtrans/add/1',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransAdd1', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalAssessment/components/applyDetail1'),
                name: '生态环境政策标准实施效果评估',
                hidden: true,
            },
            {
                path: '/achtrans/add/2',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransAdd2', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalAssessment/components/applyDetail2'),
                name: '生态环境污治理工程绩效评估',
                hidden: true,
            },
            {
                path: '/achtrans/add/3',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransAdd3', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalAssessment/components/applyDetail3'),
                name: '生态环境污染防治与修复技术实证评估',
                hidden: true,
            },
            {
                path: '/achtrans/provinceDetail',
                meta: {img: 'navbar_bg_achtrans', key: 'provinceDetail', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalIncubation/components/provinceDetail'),
                name: '孵化基金',
                hidden: true,
            },
            {
                path: '/achtrans/:id(\\d+)',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransDetail', keyParent: 'achtrans'},
                component: () => import('@/views/information/informationDetail'),
                name: '技术评估政策规范',
                hidden: true,
            },
            {
                path: '/achtrans1/:id(\\d+)',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransDetail1', keyParent: 'achtrans'},
                component: () => import('@/views/information/informationDetail'),
                name: '政策法规',
                hidden: true,
            },
            {
                path: '/achtrans2/:id(\\d+)',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransDetail2', keyParent: 'achtrans'},
                component: () => import('@/views/information/informationDetail'),
                name: '孵化基金',
                hidden: true,
            },
            {
                path: '/achtrans3/:id(\\d+)',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransDetail3', keyParent: 'achtrans'},
                component: () => import('@/views/information/informationDetail'),
                name: '基地详情',
                hidden: true,
            },*/
            {
                path: '/achtransList',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransList', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/achtransList'),
                name: '成果列表',
                hidden: false,
            },
            {
                path: '/achtransDetail/:id(\\d+)',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransDetail', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/achtransDetail'),
                name: '成果详情',
                hidden: true,
            },
            {
                path: '/achtransListPerson',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransListPerson', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalIncubation/index'),
                name: '成果上传',
                hidden: false,
            },
            {
                path: '/achtransUpload/:id(\\d+)',
                meta: {img: 'navbar_bg_achtrans', key: 'achtransUpload', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/technicalIncubation/components/demonstrationCreationUpload'),
                name: '成果上传',
                hidden: true,
            },
            {
                path: '/convertorBase',
                meta: {img: 'navbar_bg_achtrans', key: 'convertorBase', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/index'),
                name: '转移转化基地',
                hidden: false,
            }, {
                path: '/envHouseKeeper',
                meta: {img: 'navbar_bg_achtrans', key: 'envHouseKeeper', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/envHouseKeeper.vue'),
                name: '环保管家',
                hidden: false,
            }, {
                path: '/envHospital',
                meta: {img: 'navbar_bg_achtrans', key: 'envHospital', keyParent: 'achtrans'},
                component: () => import('@/views/achtrans/envHospital.vue'),
                name: '环境医院',
                hidden: false,
            },
        ]
    },
    {
        path: '',
        component: Layout,
        listFlag: true, //是否是下拉
        name: '科技帮扶',
        meta: {key: 'assistanc', scroll: '3400'},
        hidden: false,
        children: [
            {
                path: '/needHelpSupport',
                meta: {img: 'navbar_bg_demand', key: 'needHelpSupport', keyParent: 'assistanc'},
                component: () => import('@/views/demand/demandUpload'),
                name: '我需要帮扶',
                hidden: false,
            },
            {
                path: '/assistance',
                meta: {img: 'navbar_bg_hb', key: 'assistance', keyParent: 'assistanc'},
                component: () => import('@/views/assistance/index'),
                name: '帮扶队伍',
                hidden: false
            },
            {
                path: '/assistance/:id(\\d+)',
                meta: {img: 'navbar_bg_hb', key: 'assistanceDetail', keyParent: 'assistanc'},
                component: () => import('@/views/information/informationDetail'),
                name: '帮扶队伍及工作开展情况',
                hidden: true,
            },
            {
                path: '/assistance1/:id(\\d+)',
                meta: {img: 'navbar_bg_hb', key: 'assistance1Detail', keyParent: 'assistanc'},
                component: () => import('@/views/information/informationDetail'),
                name: '科技帮扶案例',
                hidden: true,
            },
        ]
    },
    {
        path: '',
        component: Layout,
        listFlag: true, //是否是下拉
        name: '专家团队',
        meta: {key: 'expertTeam', scroll: '3400'},
        hidden: true,
        children: [
            {
                path: '/expertAdd',
                meta: {img: 'navbar_bg_demand', key: 'expertAdd', keyParent: 'expertTeam'},
                component: () => import('@/views/expertAdd/index'),
                name: '专家库',
                hidden: true,
            },
        ]
    },
    {
        path: '',
        component: Layout,
        name: '关于我们',
        meta: {key: 'about_us'},
        listFlag: true, //是否是下拉
        hidden: false,
        children: [
            {
                path: '/introduction',
                meta: {img: 'navbar_bg_about_us', key: 'introduction', keyParent: 'about_us', tabId: '1'},
                component: () => import('@/views/aboutus/introduction'),
                name: '平台简介',
                hidden: false,
            },
            {
                path: '/contact',
                meta: {img: 'navbar_bg_about_us', key: 'contact', keyParent: 'about_us', tabId: '2'},
                component: () => import('@/views/aboutus/introduction'),
                name: '联系我们',
                hidden: false,
            }, {
                path: '/agreement',
                meta: {img: 'navbar_bg_about_us', key: 'agreement', keyParent: 'about_us', tabId: '3'},
                component: () => import('@/views/aboutus/introduction'),
                name: '平台协议',
                hidden: false,
            }, {
                path: '/suggestion',
                meta: {img: 'navbar_bg_about_us', key: 'suggestion', keyParent: 'about_us', tabId: '4'},
                component: () => import('@/views/aboutus/introduction'),
                name: '建议反馈',
                hidden: false,
            },
        ]
    },
    {
        path: '',
        component: Layout,
        listFlag: false, //是否是下拉
        hidden: true,
        children: [
            {
                path: '/personal',
                meta: {img: 'navbar_bg_hb', key: 'personal'},
                component: () => import('@/views/personal'),
                name: '个人中心',
                hidden: false
            }
        ]
    },
    {
        path: "*",
        redirect: "/404",
        hidden: true,
    }
];

export default new Router({
    routes: routerMap,
    mode: 'history',
    base: '/'
});
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
