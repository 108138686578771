<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      :style="{'height': height+'px', 'overflow-y': 'hidden'}"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import "@wangeditor/editor/dist/css/style.css";
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import {Boot} from '@wangeditor/editor'
import attachmentModule from '@wangeditor/plugin-upload-attachment'
import {getCookie} from "@/utils";

export default {
  name: "index",
  props: {
    content: {
      type: String,
      default: "",
    },
    func: {
      type: String,
      default: "func",
    },
    height: {
      type: Number,
      default: 500,
    },
    placeholder:{
      type: String,
      default: "请输入内容...",
    }
  },
  components: {Editor, Toolbar},
  watch: {
    html(data) {
      this.$emit(this.func.toString(), data);
    }
  },
  data() {
    return {
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/fileUpload/upload/img", // 上传的图片服务器地址
      uploadVideoUrl: process.env.VUE_APP_BASE_API + "/fileUpload/upload/video", // 上传的视频服务器地址
      annexVideoUrl: process.env.VUE_APP_BASE_API + "/fileUpload/upload/annex", // 上传的附件服务器地址
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: {},
      editorConfig: {placeholder: '请输入内容...'},
      mode: 'default', // or 'simple'
    }
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  },
  created() {
    this.editorConfig.placeholder = this.placeholder;
    // 模拟 ajax 请求，异步渲染编辑器
    console.log(2)
    this.html = this.content;
    this.editorConfig.MENU_CONF = {};
    this.editorConfig.MENU_CONF['uploadImage'] = {
      server: this.uploadImgUrl,
      fieldName: 'file',
      headers: {
        Authorization: 'Bearer ' + getCookie("Admin-Token")
      }
    }
    this.editorConfig.MENU_CONF['uploadVideo'] = {
      server: this.uploadVideoUrl,
      fieldName: 'file',
      maxFileSize: 500 * 1024 * 1024, // 500M
      headers: {
        Authorization: 'Bearer ' + getCookie("Admin-Token")
      }
    }
    console.log(Boot.plugins)
    if (Boot.plugins.length < 13) {
      //判断如果已经插入进去，不在二次插入
      Boot.registerModule(attachmentModule);
    }
    this.editorConfig.hoverbarKeys = {
      attachment: {
        menuKeys: ['downloadAttachment'], // “下载附件”菜单
      },
    }
    this.editorConfig.MENU_CONF['uploadAttachment'] = {
      server: this.annexVideoUrl, // 服务端地址
      fieldName: 'file',
      headers: {Authorization: 'Bearer ' + getCookie("Admin-Token")},
      maxFileSize: 50 * 1024 * 1024, // 50M
      timeout: 30 * 1000, // 30s
    }
    this.toolbarConfig = {
      // 插入哪些菜单
      insertKeys: {
        index: 24, // 自定义插入的位置
        keys: ['uploadAttachment'], // “上传附件”菜单
      }
    }
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
    console.log("销毁")
  }
}
</script>

<style scoped>

</style>
