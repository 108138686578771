const getters = {
  navbar: state => state.homePage.navbar,
  activePage: state => state.homePage.activePage,
  activePageParent: state => state.homePage.activePageParent,
  breadCrumb: state => state.homePage.breadCrumb,
  token: state => state.user.token,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permissions: state => state.user.permissions,
};
export default getters;
