import router from './router'
import {getToken} from '@/utils/auth'
import cache from "@/utils/cache";
import { Message } from 'element-ui'
// 需要登录的链接
const needLoginList = ['/demandUpload', '/needHelpSupport', '/technologyUpload', '/personal', '/technologyUploadNew', '/achtransUpload', '/achtransListPerson', '/technologyUploadNew/technologyAdd/0', '/expertAdd']

router.beforeEach((to, from, next) => {
    if (needLoginList.indexOf(to.path) !== -1) {
        //如果没有token 或者用户信息失效 需要重新登录
        if (getToken()&&cache.local.getJSON("userInfo")) {
            if(cache.local.getJSON("userInfo").examineStatus=='1'){
                next()
            }else{
                if(to.path.indexOf("personal")!==-1){
                    next()
                }else{
                    if(cache.local.getJSON("userInfo").examineStatus=='0'){
                        Message({
                            message: '用户信息待审核！',
                            type: 'warning'
                        });
                    }
                    if(cache.local.getJSON("userInfo").examineStatus=='2'){
                        Message({
                            message: '用户未审核通过！,未通过原因：'+cache.local.getJSON("userInfo").rejectReason,
                            type: 'warning'
                        });
                    }
                }


            }
        } else {
            next(`/login`) // 否则全部重定向到登录页
        }
    } else {
        next()
    }
})
router.afterEach(() => {
    window.scrollTo(0, 0);
})
