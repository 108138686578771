<template>
  <div class="app_common_lb app_l_r">
    <div class="app_l">
      <div class="app_l_w">
        <div class="app_l_img">帮扶队伍</div>
        <div @click="tabClick(item)" :class="{'app_l_content':true,'app_l_content_hover':activeType==item.id}" v-for="(item,index) in teams">
          {{ item.indexTitle }}
        </div>
        <div class="app_l_img">一市一策</div>
        <div @click="tabClick(item)" :class="{'app_l_content':true,'app_l_content_hover':activeType==item.id}" v-for="(item,index) in ysyc">
          {{ item.indexTitle }}
        </div>
        <div class="app_l_img">合作单位</div>
        <div class="app_l_content" @click="activeType=1" :class="{'app_l_content':true,'app_l_content_hover':activeType==1}">合作单位</div>
      </div>
    </div>
    <div class="app-r" v-if="activeType==1">
      <div class="bfLl_p">
        <div class="ll_item">
          <div class="ll_name">国家大气污染防治<br>攻关联合中心</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz1.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name" style="position: relative;top: 13px;">山东建筑大学</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz3.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name">山东省环境科学<br>研究院有限公司</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz4.jpg"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name" style="position: relative;top: 13px;">中国海洋大学</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz5.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name">山东省海洋工程<br>咨询协会</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz6.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name">中国科学院海洋<br>研究所</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz7.jpg"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name">山东省海洋资源与<br>环境研究院</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz8.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name">中国科学院大气<br>物理研究所</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz9.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name" style="position: relative;top: 13px;">山东大学</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz10.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name" style="position: relative;top: 13px;">上海大学</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz11.png"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name" style="position: relative;top: 13px;">山东省农业科学院</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz12.jpg"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name" style="position: relative;top: 13px;">山东农业大学</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz13.jpg"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name" style="position: relative;top: 13px;">山东省城建设计院</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz14.jpg"/>
          </div>
        </div>
        <div class="ll_item">
          <div class="ll_name">齐鲁工业大学<br>（山东省科学院）</div>
          <div class="img_div">
            <img src="~@/assets/images/assistance/img/fz18.png"/>
          </div>
        </div>
      </div>
    </div>
    <div class="app-r" style="min-height: 1000px" v-if="activeType!=1">
      <el-menu default-active="1" class="el-menu-demo" style="width: 100%" mode="horizontal" @select="handleSelect" v-show="showTab">
        <el-menu-item index="1">团队介绍</el-menu-item>
        <el-menu-item index="2">帮扶案例</el-menu-item>
      </el-menu>
      <div class="td_show_text" v-if="selectFlag=='1'">
        <div style="margin-bottom: 20px" v-html="teamObj.content"></div>
      </div>
      <template v-if="selectFlag=='2'">
        <!--      <el-form :model="queryParams" onsubmit="return false" ref="queryForm" size="small" :inline="true" style="width: 800px;margin: 0 auto 15px auto">
                <el-input v-model="queryParams.title" placeholder="请输入标题" clearable @keyup.enter.native="handleQuery">
                  <el-button type="primary" slot="append" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
                </el-input>
              </el-form>-->
        <el-table v-loading="loading" :data="informationListCase" :show-header="false">
          <el-table-column label="发布时间" align="center" prop="fbTime" width="160">
            <template slot-scope="scope">
              <div class="flex_column">
                <div class="time_yearmonth">{{ parseTime(scope.row.fbTime, '{y}/{m}/') }}</div>
                <div class="time_day">{{ parseTime(scope.row.fbTime, '{d}') }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="标题" align="left" show-overflow-tooltip prop="title" width="820">
            <template slot-scope="scope">
              <div class="flex_column">
                <div class="title_content" @click="gotoDetail(scope.row)">{{ scope.row.title|titlelen }}</div>
                <div class="inner_title_content">{{ scope.row.indexTitle|innertitlelen }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />
      </template>
    </div>
  </div>
</template>

<script>
import {getInformation, listInformation} from "@/api/business/information";

export default {
  name: "index",
  data() {
    return {
      teams: [],
      ysyc: [],
      showTab:true,
      informationListCase: [],
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: "正式",
        type: 22
      },
      loading: false,
      activeType: 287,
      // 总条数
      total: 0,
      teamObj: {},
      selectFlag: '1'
    }
  },
  mounted() {
    if (this.$route.query.activeType) {
      this.activeType = Number(this.$route.query.activeType);
      this.handleSelect(this.selectFlag);
    } else {
      this.getList();
    }
    this.getTeamObj();
    this.getTeamArticle();
    this.getYsyc();
  },
  methods: {
    /** 查询资讯管理列表 */
    getList() {
      this.loading = true;
      listInformation(this.queryParams).then(response => {
        this.informationListCase = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    getTeamArticle() {
      let param = {
        pageNum: 1,
        pageSize: 10,
        status: "正式",
        type: 13
      }
      listInformation(param).then(response => {
        this.teams = response.rows;
        this.total = response.total;
        this.loading = false;
      });
    },
    getYsyc() {
      let param = {
        pageNum: 1,
        pageSize: 10,
        status: "正式",
        type: 29
      }
      listInformation(param).then(response => {
        this.ysyc = response.rows;
      });
    },
    gotoDetail(row) {
      this.$router.push({path: 'assistance/' + row.id})
    },
    tabClick(item) {
      this.activeType = item.id;
      this.getTeamObj();
      if(item.type==29){
        this.showTab=false;
        this.selectFlag=1;
      }else{
        this.showTab=true;
        this.handleSelect(this.selectFlag);
      }

    },
    getTeamObj() {
      this.teamObj = {};
      getInformation(this.activeType).then(response => {
        this.teamObj = response.data;
      });
    },
    handleSelect(key, keyPath) {
      this.queryParams.pageNum = 1;
      this.queryParams.title = '';
      switch (this.activeType) {
        case 285:
          this.queryParams.type = 22;
          break;
        case 286:
          this.queryParams.type = 24;
          break;
        case 287:
          this.queryParams.type = 25;
          break;
        case 288:
          this.queryParams.type = 23;
          break;
        case 335:
          this.queryParams.type = 26;
          break;
        case 483:
          this.queryParams.type = 36;
          break;
      }
      this.selectFlag = key;
      this.getList();
    },
  },
}
</script>

<style scoped lang="scss">
.title_clu:hover {
  cursor: pointer;
  color: #3c9bf1;
}

.el-menu-demo {
  font-weight: 700;

  li {
    font-size: 18px;
  }
}

.time_yearmonth {
  width: 80px;
  height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 39px
}

.time_day {
  width: 80px;
  height: 40px;
  font-size: 16px;
  text-align: right;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 39px;

  background: linear-gradient(-4deg, #2671E0 0%, #4198E6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title_content {
  width: 380px;
  height: 32px;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  line-height: 39px;
  cursor: pointer;

}

.inner_title_content {
  width: 791px;
  height: 43px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 30px;
}

.title_div {
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  color: #2671E0;
}

.bfLl_p {
  .ll_item {
    height: 285px;
    width: 23%;
    display: inline-table;
    text-align: center;
    margin: 0 1% 20px 1%;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    box-shadow: 0 0 15px #d0d0d0;
    padding: 25px;

    .ll_name {
      margin-bottom: 20px;
      height:50px;
      font-size: 18px;
      font-weight: 500;
    }

    .img_div {
      height: 160px;
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
    }

    img {
      width: 70%;
    }
  }
}
</style>
