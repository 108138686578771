<template>
  <div class="layout">
    <HeaderBar></HeaderBar>
    <AppMain style="background: #ffffff;"></AppMain>
    <FooterBar></FooterBar>
  </div>
</template>

<script>
import AppMain from "@/views/layout/components/AppMain";
import HeaderBar from "@/views/layout/components/HeaderBar";
import FooterBar from "@/views/layout/components/FooterBar";

export default {
  name: "Layout",
  components: {HeaderBar, FooterBar, AppMain}
}
</script>

<style scoped>
  .layout {
    /*max-width: 1920px;*/
    min-width: 1400px;
    margin: 0 auto;
  }
</style>
